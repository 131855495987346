export type Sponsor = {
  name: string;
  link: string;
  logoUrl: string;
}

export const euroSponsors : Record<string, Sponsor> = {
  DZ: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_262643m_18609c__[]general[]_d32951_l136238_odds&site=262643&ad=18609',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  BD: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_14249c_1stDep2024__[]general[]_d32951_l136297_banner&site=273081&ad=14249&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  BR: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_29217c_1stDep2024__[]general[]_d32951_l136300_banner&site=273081&ad=29217&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  CA: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_21895c_1stDep2024__[]general[]_d32951_l136306_banner&site=273081&ad=21895&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  CI: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_45077c_1stDep2024__[]general[]_d32951_l136315_banner&site=273081&ad=45077&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  EG: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_18607c_1stDep2024__[]general[]_d32951_l136319_banner&site=273081&ad=18607&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  GH: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_14225c_1stDep2024__[]general[]_d32951_l136338_banner&site=273081&ad=14225&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  IN: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_2895c_1stDep2024__[]general[]_d32951_l136339_banner&site=273081&ad=2895&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  ID: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_22719c_1stDep2024__[]general[]_d32951_l136340_banner&site=273081&ad=22719&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  IQ: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_262643m_18217c__[]general[]_d32951_l65267_odds&site=262643&ad=18217',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  JP: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_22269c_1stDep2024__[]general[]_d32951_l136342_banner&site=273081&ad=22269&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  KE: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_2804c_1stDep2024__[]general[]_d32951_l136343_banner&site=273081&ad=2804&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  LB: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_57695c_1stDep2024__[]general[]_d32951_l136344_banner&site=273081&ad=57695&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  MY: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_23455c_1stDep2024__[]general[]_d32951_l136345_banner&site=273081&ad=23455&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  ML: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_21103c_1stDep2024__[]general[]_d32951_l136348_banner&site=273081&ad=21103&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  MA: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_21123c_1stDep2024__[]general[]_d32951_l136350_banner&site=273081&ad=21123&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  NP: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_32273c_1stDep2024__[]general[]_d32951_l136353_banner&site=273081&ad=32273&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  NG: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_14987c_1stDep2024__[]general[]_d32951_l136354_banner&site=273081&ad=14987&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  RU: {
    name: '1xbet',
    link: 'https://refpanpmjvhl.top/L?tag=s_262643m_355c__[]general[]_d32951_l26295_odds&site=262643&ad=355',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  SA: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_29145c_1stDep2024__[]general[]_d32951_l136356_banner&site=273081&ad=29145&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  SG: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_36159c_1stDep2024__[]general[]_d32951_l136360_banner&site=273081&ad=36159&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  SO: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_38951c_1stDep2024__[]general[]_d32951_l136363_banner&site=273081&ad=38951&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  KR: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_23799c_1stDep2024__[]general[]_d32951_l136364_banner&site=273081&ad=23799&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  TH: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_14243c_1stDep2024__[]general[]_d32951_l136366_banner&site=273081&ad=14243&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  TR: {
    name: '1xbet',
    link: 'https://refpa28543.top/L?tag=d_273081m_1264c_1stDep2024__[]general[]_d32951_l136367_banner&site=273081&ad=1264&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  AE: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_32283c_1stDep2024__[]general[]_d32951_l136368_banner&site=273081&ad=32283&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  MX: {
    name: '1xbet',
    link: 'https://affpa.top/L?tag=d_273081m_14203c_1stDep2024__[]general[]_d32951_l136349_banner&site=273081&ad=14203&r=registration/',
    logoUrl: 'http://images.fotmob.com/images/betting/1xbet.jpg',
  },
  CH: {
    name: 'Interwetten',
    link: 'https://www.interwetten16.com/go/?dl=1190&bn=coop_11630-fotmob-ch_logo&utm_source=coop_11630-fotmob-com&utm_medium=logo&utm_campaign=newcustomerbonus-sb_2024_C_com&utm_content=de_sb_newcustomerbonus_generic',
    logoUrl: 'https://images.fotmob.com/image_resources/upload/IWlogo2024.png',
  },
};
