import { z } from 'zod';

const teamOddsSchema = z.object({
  name: z.string(),
  odds: z.string().optional(),
  oddsDecimal: z.union([z.string(), z.number()]).optional(),
  oddsAmerican: z.string().optional(),
  enetpulseId: z.number(),
  selectionValue: z.string(),
  deepLink: z.string().url(),
});

const countryConfig = z.object({
  countryCode: z.string(),
  startPageLink: z.string().url().optional(),
  endPageLink: z.string().url().optional(),
  gambleAwareMsg: z.string(),
  betTemplate: z.string().optional(),
  logoUrl: z.string().url(),
  betProviderName: z.string().optional(),
});

const gameConfig = z.object({
  gameId: z.number(),
  countryConfig: z.array(countryConfig),
  odds: z.array(teamOddsSchema),
});

const oddsSchema = z.object({
  odds: z.array(teamOddsSchema).optional(),
  gameConfig: z.array(gameConfig).optional(),
  countryConfig: z.array(countryConfig).optional(),
});

const fetchStatusSchema = z.object({
  fetching: z.boolean().optional(),
  fetched: z.boolean().optional(),
});

export const oddsFetchingSchema = fetchStatusSchema.merge(oddsSchema);

export type TeamOdds = z.infer<typeof teamOddsSchema>;
export type OddsFetching = z.infer<typeof oddsFetchingSchema>;
