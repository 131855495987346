import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { identity } from 'lodash/fp';
import { getSponsorOverrides, promoTeams } from './overrides';
import { TeamOdds, oddsFetchingSchema } from './schema';

type State = {
  odds?: Record<string, any>;
  server?: Record<string, any>;
  tournament?: Record<string, any>;
  location?: {
    countryCode?: string;
    regionId?: string;
  };
}

type OddsConfig = {
  showOdds?: boolean;
  showSponsoredBy?: boolean;
  odds?: Record<string, any>[];
  startPageLink?: string;
  endPageLink?: string;
  gambleAwareMsg?: string;
  betTemplate?: string;
  logoUrl?: string;
  betProviderName?: string;
  promoTeamId?: number;
  getFormat?: (team: TeamOdds) => string;
};

export const useOddsConfig = (): OddsConfig => {
  const rawOddsData = useSelector((state: State) => state.odds || {});
  const { success, data } = oddsFetchingSchema.safeParse(rawOddsData);

  if (!success) {
    return {};
  }

  const {
    gameConfig = [],
    odds = [],
    countryConfig: fallbackCountryConfig = [],
  } = data;

  const tournamentId = useSelector((state: State) => state.server?.tournament?.tournamentId
    ?? state.tournament?.tournamentId);

  const countryConfig = gameConfig
    ?.find(({ gameId }) => gameId === tournamentId)
    ?.countryConfig ?? fallbackCountryConfig;

  const location = useSelector((state: State) => state?.location || {});

  const promoTeamId = promoTeams[tournamentId]?.[location.countryCode]?.[0];

  let currentConfig = countryConfig
    .find(({ countryCode }) => countryCode === location.countryCode);
  if (!currentConfig) {
    currentConfig = countryConfig.find(({ countryCode }) => countryCode === `${location.countryCode}_${location.regionId}`);
  }

  if (!currentConfig) {
    const sponsor = getSponsorOverrides(tournamentId, location.countryCode);
    return {
      showOdds: false,
      showSponsoredBy: !!sponsor,
      startPageLink: sponsor?.link,
      logoUrl: sponsor?.logoUrl,
      betProviderName: sponsor?.name,
    };
  }

  const getFormat = (team: TeamOdds): string => {
    switch (location?.countryCode) {
      case 'UK':
      case 'GB': {
        return team.odds;
      }
      case 'US': {
        if (team.oddsAmerican) {
          return `+${team.oddsAmerican}`;
        }
        return team.oddsDecimal?.toString();
      }
      default: {
        return team.oddsDecimal?.toString();
      }
    }
  };

  const oddsArray = Array.isArray(odds) ? odds : [];
  return {
    odds: oddsArray,
    ...currentConfig,
    getFormat,
    promoTeamId,
    showOdds: oddsArray.length > 0,
    showSponsoredBy: oddsArray.length > 0,
  };
};

export const useShowOdds = () => {
  const { showOdds } = useOddsConfig();
  return showOdds;
};

export const useShowSponsoredBy = () => {
  const { showSponsoredBy } = useOddsConfig();
  return showSponsoredBy;
};

export const useGetOddsForTeam = () => {
  const { odds = [], getFormat = () => { } } = useOddsConfig();

  return useCallback((teamId) => {
    try {
      const team = odds?.find(({ enetpulseId }) => enetpulseId === teamId) ?? {};
      return {
        value: getFormat(team),
        deepLink: team.deepLink,
      };
    } catch (error) {
      return {};
    }
  }, [odds, getFormat]);
};

type OddsValue = {
  value?: string;
  deepLink?: string;
}

export const useOddsForTeam = (teamId): OddsValue => {
  try {
    const { odds = [], getFormat = identity } = useOddsConfig();
    const team = odds?.find(({ enetpulseId }) => enetpulseId === teamId) ?? {};
    return {
      value: getFormat(team),
      deepLink: team.deepLink,
    };
  } catch (error) {
    return {};
  }
};
