import { Sponsor, euroSponsors } from './sponsors';

const EURO_2024 = 502024;
const COPA_AMERICA_2024 = 442024;

export const promoTeams = {
  [EURO_2024]: {
    GB: [8491],
  },
  [COPA_AMERICA_2024]: {
    US: [6713],
  },
};

const enGB = 'en-GB';

const toWinTexts : Record<string, string> = {
  en: 'to win outright',
  [enGB]: 'to win outright',
};

export const getToWinText = (locale: string): string => toWinTexts[locale] || 'to win';

export type LogoStyling = {
  cornerRadius?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
};

export const logoModifications = (providerName: string): LogoStyling => {
  // We need to apply border radius to the Bet365 logo
  if (providerName === 'Bet365') {
    return { cornerRadius: '7px' };
  }

  // The NorskTipping logo
  return { objectFit: 'contain' };
};

export const getSponsorOverrides = (tournamentId: number, countryCode: string): Sponsor | null => {
  switch (tournamentId) {
    case EURO_2024:
      return euroSponsors[countryCode] ?? null;
    default:
      return null;
  }
};
