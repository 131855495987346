import React, { FC } from 'react';
import { useTranslation } from 'lib/i18n/useTranslation';
import styled, { css } from 'styled-components';
import { useOddsConfig } from 'lib/odds';
import { Icon18Pluss } from '../SVGs/icon-18-pluss';
import { Logo } from './Logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
  gap: 12px;
`;

const Link = styled.a`
  text-decoration: none;
  cursor: ${({ href }) => (href ? 'pointer' : 'auto')};
`;

const Content = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  align-items: center;
`;

const Text = styled.span<{ horizontal: boolean }>`
  font-size: 13px;
  text-align: right;
  color: ${({ horizontal, theme }) => (horizontal
    ? theme.startPageText
    : theme.sponsored2)};
  ${({ horizontal }) => (horizontal
    ? css`margin-right: 15px;`
    : css`margin-bottom: 15px;`
  )}
`;

const DisclaimerContainer = styled.span<{ horizontal: boolean }>`
  font-size: 12px;
  color: ${({ horizontal, theme }) => (horizontal
    ? theme.frontDisclaimer
    : theme.sponsored2)};
  fill: ${({ horizontal, theme }) => (horizontal
    ? theme.frontDisclaimer
    : theme.sponsored2)};
  display: flex;
  gap: 8px;
  align-items: center;
`;

type DisclaimerProps = {
  message: string;
  horizontal?: boolean;
};

const prefixIcons: Record<string, () => JSX.Element> = {
  '18+': Icon18Pluss,
};

const Disclaimer: FC<DisclaimerProps> = ({ message, horizontal }) => {
  const kvp = Object.entries(prefixIcons).filter(([k]) => message.startsWith(k))[0];
  if (!kvp) {
    return <DisclaimerContainer horizontal={horizontal}>{message}</DisclaimerContainer>;
  }

  const [key, Icon] = kvp;
  const newMessage = message.substring(key.length);
  return (
    <DisclaimerContainer horizontal={horizontal}>
      <Icon />
      {' '}
      {newMessage}
    </DisclaimerContainer>
  );
};

type SponsoredByProps = {
  horizontal: boolean;
  href: string;
  disclaimer?: string;
  text: string;
  logoUrl: string;
  provider: string;
};

export const SponsoredBy: FC<SponsoredByProps> = ({
  horizontal,
  href,
  disclaimer,
  text,
  logoUrl,
  provider,
}) => (
  <Container>
    <Link
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Content horizontal={horizontal}>
        <Text horizontal={horizontal}>{text}</Text>
        <Logo src={logoUrl} name={provider} />
      </Content>
    </Link>
    {disclaimer && <Disclaimer horizontal={horizontal} message={disclaimer} />}
  </Container>
);

type SponsoredByContainerProps = {
  horizontal: boolean;
};

type SponsoredByContainerWithLinkProps = SponsoredByContainerProps & {
  disableLink?: boolean;
};

const SponsoredByContainer: FC<SponsoredByContainerWithLinkProps> = ({
  horizontal,
  disableLink,
}) => {
  const { t } = useTranslation();
  const {
    showSponsoredBy,
    startPageLink,
    gambleAwareMsg,
    logoUrl,
    betProviderName,
  } = useOddsConfig();

  if (!showSponsoredBy) {
    return null;
  }

  return (
    <SponsoredBy
      logoUrl={logoUrl}
      horizontal={horizontal}
      href={disableLink ? null : startPageLink}
      disclaimer={gambleAwareMsg}
      text={t('sponsored_by')}
      provider={betProviderName}
    />
  );
};

export const SponsoredByWithoutDisclaimer: FC<SponsoredByContainerWithLinkProps> = ({
  horizontal,
  disableLink,
}) => {
  const { t } = useTranslation();
  const {
    showSponsoredBy,
    startPageLink,
    logoUrl,
    betProviderName,
  } = useOddsConfig();

  if (!showSponsoredBy) {
    return null;
  }

  return (
    <SponsoredBy
      logoUrl={logoUrl}
      horizontal={horizontal}
      href={disableLink ? null : startPageLink}
      text={t('sponsored_by')}
      provider={betProviderName}
    />
  );
};

export const SponsoredByDisclaimer: FC<SponsoredByContainerProps> = ({
  horizontal,
}) => {
  const {
    showSponsoredBy,
    gambleAwareMsg,
  } = useOddsConfig();

  if (!showSponsoredBy || !gambleAwareMsg) {
    return null;
  }

  return (
    <Disclaimer horizontal={horizontal} message={gambleAwareMsg} />
  );
};

export default SponsoredByContainer;
