import { logoModifications } from 'lib/odds/overrides';
import Image from 'next/image';
import React, { FC } from 'react';
import styled from 'styled-components';

export type LogoProps = {
  src: string;
  name?: string;
  width?: number;
  height?: number;
};

const LogoImage = styled(Image) <{ alt: string, width: number, height: number }>`
  ${({ alt }) => (logoModifications(alt).cornerRadius
    ? `border-radius: ${logoModifications(alt).cornerRadius};`
    : ''
  )}
  ${({ alt }) => (logoModifications(alt).objectFit
    ? `object-fit: ${logoModifications(alt).objectFit};`
    : ''
  )}
`;

export const Logo: FC<LogoProps> = ({
  src, name, width = 75, height = 30,
}) => (
  <LogoImage
    src={src}
    alt={name ?? 'logo'}
    width={width}
    height={height}
    layout="fixed"
  />

);
